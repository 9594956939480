var intialState = { };
    
export default function brokerage(state=intialState,action){
	switch (action.type) {
  		case 'ADD_BROKERAGE_FILTERS':
    	return {...state, brokerageFilters:action.payload }

	  	case 'GOT_INCOME_REPORT_DATA':
      return {...state, incomeReportData:action.payload}

      case 'FAILED_INCOME_REPORT_DATA':
      return {...state, incomeReportData:action.payload}

      case 'GOT_INCOME_REPORT_SUMMARY':
      case 'FAILED_INCOME_REPORT_SUMMARY':
      case 'GOT_INCOME_REPORT_DETAILED_SUMMARY':
      case 'FAILED_INCOME_REPORT_DETAILED_SUMMARY':
      return {...state, incomeReportSummary:action.payload}

  		case 'COMPONENT_LOADER_FOR_BROKERAGE_TABLE':
    	return {...state, loaderBrokerage:action.payload}

      case 'GOT_INCOME_REPORT_DETAILED_DATA':
      return {...state, incomeReportData:action.payload}

      case 'FAILED_INCOME_REPORT_DETAILED_DATA':
      return {...state, incomeReportData:action.payload}

      case 'NEW_MERGED_GROUPBY':
      return {...state, mergedGroupBy:action.payload}

      case 'SET_LOWER_REPORTING_INFO':
	  return {...state, lowerReportingInfo:action.payload }
	  
	  case 'COMPONENT_LOADER_AUM_BUSINESS':
    	return {...state, loaderAumBusiness:action.payload}

      case 'GOT_AUM_BUSINESS_REPORT_DATA':
      return {...state, aumBusinessReportData:action.payload}

      case 'FAILED_AUM_BUSINESS_REPORT_DATA':
	  return {...state, aumBusinessReportData:action.payload}

    case 'GOT_ANALYSIS_REPORT_DATA':
      return {...state, analysisReportData:action.payload}
    case 'FAILED_ANALYSIS_REPORT_DATA':
      return {...state, analysisReportData:action.payload}

    case 'ADD_BROKERAGE_ANALYSIS_FILTERS':
     return {...state, brokerageAnalysisFilters: action.payload}


    case 'GOT_VARIANCE_REPORT_DATA':
      return {...state, varianceReportData:action.payload}

    case 'FAILED_VARIANCE_REPORT_DATA':
      return {...state, varianceReportData:action.payload}

    case 'GOT_SUB_BROKER_TXNS_DATA':
      return {...state, subBrokerTxnsData: action.payload }
    case 'FAILED_SUB_BROKER_TXNS_DATA':
      return {...state, subBrokerTxnsData:action.payload}

    case 'SUCCESS_APPLY_RATE_REQUEST':
        return { ...state, notificationData: action.payload, errorNotification : false }
    case 'FAILED_APPLY_RATE_REQUEST':
        return { ...state, notificationData: action.payload, errorNotification : true }

    case 'SUCCESS_UPDATE_TXNS_SB_WISE':
        return { ...state, notificationData: action.payload, errorNotification : false }
    case 'FAILED_UPDATE_TXNS_SB_WISE':
        return { ...state, notificationData: action.payload, errorNotification : true }

    case 'GET_EARNING_RATE_DATA':
        return { ...state, earningRate: action.payload}
    case 'FAILED_EARNING_RATE_DATA':
        return { ...state, earningRate: action.payload}    
    case 'UPDATE_EARNING_RATE':
        return { ...state, notificationData: action.payload }
    case 'FAILED_UPDATE_EARNING_RATE':
        return { ...state, notificationData: action.payload } 
        
    case 'GOT_RECONCILIATION_REPORT_DATA':
        return { ...state, incomeReportData: action.payload}
    case 'FAILED_RECONCILIATION_REPORT_DATA':
        return { ...state, incomeReportData: action.payload}
    case 'ADD_BROKERAGE_RECONCILIATION_FILTERS':
        return {...state, brokerageReconciliationFilters:action.payload }
    
    case 'GOT_BULK_UPLOAD_EARNING_RATE':
        return { ...state, bulkUploadEarningRate: action.payload }
    case 'FAILED_BULK_UPLOAD_EARNING_RATE':
        return { ...state, bulkUploadEarningRate: action.payload } 
        
    case 'CREATE_LOST_BROKERAGE_JOB':
      return { ...state, lostBrokerage: action.payload }
    case 'FAILED_TO_CREATE_LOST_BROKERAGE_JOB':
      return { ...state, lostBrokerage: action.payload }

    case 'CREATE_BROKERAGE_RECO_JOB':
    case 'FAILED_TO_CREATE_BROKERAGE_RECO_JOB':
      return { ...state, createBrokerageJob: action.payload }

    case 'LAST_UPDATED_BROKERAGE_RECO':
    case 'FAILED_LAST_UPDATED_BROKERAGE_RECO':
      return { ...state, lastUpdatedBrokrage: action.payload }

    case 'CLEAR_NOTIFICATION' :
        return {...state, notificationData : action.payload }

    	default:
      	new Error('No action type matched in reducer')
	}
	return state
}